<template>
    <div class="right-content-inner" v-loading="loading">
      <ul class="list" v-for="item in menu" :key="item.id">
        <li >
          <div class="right-title">{{ item.title }}</div>
          <div v-html="item.html" class="sub-desc mt_48" ></div>
        </li>
        <li class="right-item" v-for="i in item.children" :key="i.id">
          <div class="right-sub-title">{{ i.title }}</div>
          <template v-if="i?.children">
            <div class="course-content mt_48" v-for="(j,index) in i.children" :key='j.id' :id="j.id">
              <div class="content-title">{{index + 1}}.{{ j.title }}</div>
              <div class="content-desc" v-html="j.html"></div>
              <template v-if="j.img && j.img.length">
                <img v-for="k in j.img" :src="k" alt="位元灵感" :key="k" class="content-img mb_8" :class="[j.pda?'pda':'']"/>
              </template>
            </div>
          </template>
        </li>
      </ul>
    </div>
</template>

<script setup>
import {getCurrentInstance, inject, onMounted, ref, watch} from "vue";
import MenuList from '../leftMenu/MenuList/config'
import {useRoute} from "vue-router";
import _ from 'lodash'

const MenuListNew = _.cloneDeep(MenuList)
const loading = inject('loading')
const menu = ref([])
const route = useRoute()
const {proxy} = getCurrentInstance();
const getMenu =()=>{
  const id = Number(route.params.id)
  let list = []
  MenuListNew.forEach(item =>{
    item.children.forEach(i=>{
      if(i.id === id ){
        item.children = [i]
        list.push(item)
      }
    })
  })
  return list
}
onMounted(()=>{
  menu.value = getMenu()
})
watch(()=>loading,(val)=>{
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "rightContent",
});
</script>

<style lang="scss" scoped>
.right-content-inner {
  padding-bottom: 100px;
  .list {
    .right-title {
      font-size: 30px;
      color: $shopFsColor;
      line-height: 1;
    }
    .sub-desc{
      :deep(div){
        font-size: 16px;
      }
    }

    .right-item {
      margin-top: 48px;

      .right-sub-title {
        font-size: 24px;
        color: $shopFsColor;
        line-height: 1;
      }

      .content-title {
        font-size: 20px;
        color: $shopFsColor;
        line-height: 1;
        font-weight: bold;
      }

      .content-desc {
        font-size: 16px;
        color: $shopFsColor;
        line-height: 1.5;
        margin-top: 20px;
      }

      .content-img {
        margin-top: 30px;
        width: 99%;
        height: auto;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 1px 3px 12px 0 rgba(192, 198, 206, 0.5);
        &.pda{
          width: 40%;
          display: block;
          margin: 30px 0;
        }
      }
    }
  }
}
</style>
